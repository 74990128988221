@font-face {
  font-family: "Work Sans";
  src: url("assets/fonts/WorkSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Work Sans";
  src: url("assets/fonts/WorkSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Work Sans";
  src: url("assets/fonts/WorkSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Work Sans";
  src: url("assets/fonts/WorkSans-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font: 400 14px / 20px "Work Sans", sans-serif !important;
}

/* .p-button {
  background-color: #673ab7;
} */

.mat-drawer-inner-container {
  overflow: hidden !important;
}

.mat-mdc-menu-item-text,
.mdc-button__label {
  display: flex !important;
}

.mat-mdc-menu-item-text .material-symbols-outlined,
.mdc-button__label .material-symbols-outlined {
  margin-right: 10px;
}

.activebutton {
  font-weight: bold;
  color: rgb(84, 5, 196) !important;
}

.activebuttonb {
  border: solid rgb(84, 5, 196) 2px !important;
  color: rgb(84, 5, 196) !important;
}

#search-input .p-autocomplete {
  width: 100% !important;
  display: flex;
  justify-content: center;
}

#search-input .p-autocomplete-multiple-container {
  width: 80%;
}

.btn-signin .mdc-button__label {
  width: 100% !important;
}

.p-datatable .p-datatable-tbody>tr>td {
  padding: .1rem 1rem;
  line-height: 1.2;
}

.mat-expansion-panel-header-custom2 .mat-content-hide-toggle {
  margin: 0 !important;
}

.tooltip-custom .mat-mdc-tooltip-surface {
  background: black !important;
}

.mat-mdc-tooltip-trigger {
  touch-action: auto !important;
  -webkit-user-drag: auto !important;
}

.full-width-dialog.cdk-overlay-pane.mat-mdc-dialog-panel {
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
}

.full-width-dialog.cdk-overlay-pane.mat-mdc-dialog-panel .mat-mdc-dialog-surface {
  border-radius: 0;
}

webcam>div>video {
  width: 100%;
  height: auto;
  max-height: 100%;
  max-width: 100%;
}